import React from "react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
function Lists({ data, ondel, iconset }) {
  return (
    <ul role="list" className="divide-y divide-gray-100">
      {data &&
        data.map((items, i) => (
          <li key={i} className="flex justify-between gap-x-6 py-3">
            <div className="flex min-w-0 gap-x-4">
              <p className="mt-1">{i + 1} . </p>
              {iconset}
              <div className="min-w-0 flex-auto">
                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                  <a
                    href={`${items.url}`}
                    className="truncate hover:underline hover:text-blue-500"
                  >
                    {"https://www.youtube.com/watch?v=" + items.url}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-6">
              <XMarkIcon
                onClick={ondel}
                className="h-5 w-5 cursor-pointer text-rose-500"
                aria-hidden="true"
              />
            </div>
          </li>
        ))}
    </ul>
  );
}

export default Lists;
