import React, { useEffect, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import logo from "../../assets/images/icon.png";
import { auth} from "../../firebase/config";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const authUser = auth.currentUser
 const[loading,setLoadin] = useState(true)
  
  const initialState = {
    email: "",
    password: "",
  };
  const [login, setLogin] = useState(initialState);
  const [errmsg, setErrmsg] = useState("");
  const inputHandle = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
    setErrmsg("");
  };
  const formSubmit = (e) => {
    e.preventDefault();
    const { email, password } = login;
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        navigate("/");

        // ...
      })
      .catch((error) => {
        setErrmsg(error.code.split("/")[1]);
      });
  };
  const getAuth = ()=>{
    if(authUser){
      return navigate("/")
    }
  }
  useEffect(()=>{
    getAuth()
  })
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-10 w-auto" src={logo} alt="X-wealth" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account 
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={formSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                onChange={inputHandle}
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <p style={{ color: "red" }}>{errmsg}</p>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                onChange={inputHandle}
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <p style={{ color: "red" }}>{errmsg}</p>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
