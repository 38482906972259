import React, { useEffect, useState, Fragment, useRef } from "react";
import {
  db,
  groupscollectionRef,
  userscollectionRef,
} from "../../firebase/config";
import { getDocs, deleteDoc,updateDoc,doc } from "firebase/firestore";
import { Dialog, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  HeartIcon,
  PencilIcon,
  TrashIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import groupnull from "../../assets/images/wealth.jpg";

function Groups() {
  const cancelButtonRef = useRef(null);
  //state for the modal to add a new group
  const initialState = {
    name:"",
    group_id:"",
    description:"",
    groupUsername:""

  }
  const[imagefile,setImagefile] = useState("")
  const [state,setState] = useState(initialState)
  const [data, setData] = useState([]);
  const [deleteID, setDeleteid] = useState("");
  const [open, setOpen] = useState(false);
  const [uopen, setUopen] = useState(false);
  const [dopen, setDopen] = useState(false);
  const [search, setSearch] = useState("");
  const [name, setName] = useState("loading...");
  const [details, setDetails] = useState();
const inputHandle = (e)=>{
  const{name,value} = e.target
  setState({...state,[name]:value})
}
// update groups information
const formUpdate = async(e)=>{
  e.preventDefault();
  const userDoc = doc(db,"Groups",state.group_id)
  await updateDoc(userDoc,state)
  console.log("updated");
  getGroupList()
}



  const getGroupList = async () => {
    try {
      const data = await getDocs(groupscollectionRef);
      const res = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setData(res);
    } catch (error) {}
  };

  const getuser = async (id) => {
    try {
      const data = await getDocs(userscollectionRef);
      const res = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      const user = res.find((u) => u.userId === id);
      setName(user.firstName + " " + user.lastName);
      return true;
    } catch (error) {
      return "Not";
    }
  };

  // delete group
  const deleteVideo = async () => {
    try {
      const groupdeleteRef = doc(db, "Groups", deleteID);
      await deleteDoc(groupdeleteRef);
      setDopen(false);
      alert("Group deleted successfully");
      getGroupList();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getGroupList();
  }, []);
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Groups
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the groups in your account including their group
              name, owner, rank and rating.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <div className="relative mt-2 flex items-center">
              <input
                type="text"
                name="search"
                onChange={(e) => setSearch(e.target.value)}
                id="search"
                className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-800 sm:text-sm sm:leading-6"
              />
              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                  Search
                </kbd>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Group Name
                      </th>
                    
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Created By
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Rank
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data
                      .filter((item) => {
                        return search.toLowerCase() === ""
                          ? item
                          : item.name.toLowerCase().includes(search);
                      })
                      .map((g, i) => (
                        <tr key={i}>
                          <td className="whitespace-nowrap py-4 pl-4 capitalize pr-3 text-sm cursor-pointer hover:text-gray-400 font-medium text-gray-900 sm:pl-6">
                          <p
                              onClick={() => {
                                setDetails(g);
                                setOpen(true);
                              }}
                            >
                              {" "}
                              {g.name}
                            </p>
                           
                           <small className="text-gray-400" >{g.id}</small>
                          </td>
                        
                          <td className="whitespace-nowrap capitalize px-3 py-4 text-sm text-gray-500">
                            {getuser(g.created_by) ? name : "Not Found"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {" "}
                            1
                          </td>
                          <td className="whitespace-nowrap flex gap-4 px-3 py-4 text-sm text-green-500">
                            <PencilIcon
                              onClick={() =>{
                                setState({...state,name:g.name,group_id:g.id,description:g.description,groupUsername:g.groupUsername})
                                 setUopen(true)
                                }}
                              className="h-4 w-4 cursor-pointer "
                            />
                            <TrashIcon
                              onClick={() => {
                                setDopen(true);
                                setDeleteid(g.id);
                              }}
                              className="h-4 w-4 cursor-pointer text-rose-500 "
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-96">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    {details ? (
                      <div className="h-full overflow-y-auto bg-white p-8">
                        <div className="space-y-6 pb-16">
                          <div>
                            <div className="aspect-h-7 relative aspect-w-10 rounded-md block w-full overflow-hidden ">
                            
                              <img
                                src={
                                  details.profile_pic != null
                                    ? details.profile_pic
                                    : groupnull
                                }
                                alt=""
                                className="h-20 w-20 rounded-full"
                              />
                              <label htmlFor="upload_img" >
                              <PencilIcon
                              className="h-7 w-7 p-2 absolute bg-slate-800 rounded-full text-white top-2 left-16 cursor-pointer "
                            />
                            </label>
                            <input type="file" className="hidden"  id="upload_img"/>
                            </div>
                            <div className="mt-4 flex items-start justify-between">
                              <div>
                                <h2 className="text-base font-semibold capitalize leading-6 text-gray-900">
                                  <span className="sr-only">Details for </span>
                                  Name : {details.name}
                                </h2>
                                <p className="text-sm font-medium text-gray-500">
                                  Group ID : {details.groupId}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900">
                              Information
                            </h3>
                            <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Rank</dt>
                                <dd className="text-gray-900">1</dd>
                              </div>
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Admin</dt>
                                <dd className="text-gray-900">
                                  {" "}
                                  {getuser(details.created_by)
                                    ? name
                                    : "Not Found"}
                                </dd>
                              </div>
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Total User</dt>
                                <dd className="text-gray-900">
                                  {details.totalUsers}
                                </dd>
                              </div>
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Risk</dt>
                                <dd className="text-gray-900">
                                  {details.risk_profile}
                                </dd>
                              </div>
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Experience</dt>
                                <dd className="text-gray-900">
                                  {details.experience}
                                </dd>
                              </div>
                            </dl>
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900">
                              Description
                            </h3>
                            <div className="mt-2 flex items-center justify-between">
                              <p className="text-sm italic text-gray-500">
                                {details.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* modals update group */}
      <Transition.Root show={dopen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDopen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-rose-100">
                      <XMarkIcon
                        className="h-6 w-6 text-rose-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Are you Sure ?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Do you really want to delete these records? This
                          process cannot be undone
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center gap-4">
                    <button
                      type="button"
                      className="inline-flex  justify-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 outline-none"
                      onClick={() => setDopen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex  justify-center rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500"
                      onClick={() => {
                        deleteVideo();
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* update group details */}
      <Transition.Root show={uopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setUopen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <p>
                    Group Details
                  </p>
                  <form className="mx-auto  max-w-xl sm:mt-5" onSubmit={formUpdate}>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Group name
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={state.name}
                            onChange={inputHandle}
                            autoComplete="name"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="group_id"
                          className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Group ID
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="group_id"
                            id="group_id"
                            value={state.group_id}
                            autoComplete="group_id"
                            disabled
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="description"
                          className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Description
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="description"
                            id="description"
                            value={state.description}
                            onChange={inputHandle}
                            autoComplete="description"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="groupUsername"
                          className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                         GroupUsername
                        </label>
                        <div className="mt-2.5">
                          <input
                            type="text"
                            name="groupUsername"
                            onChange={inputHandle}
                            value={state.groupUsername}
                            id="groupUsername"
                            autoComplete="groupUsername"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                     
                    
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-gray-800 px-3 py-2 text-sm  text-white shadow-sm hover:bg-gray-900 sm:ml-3 sm:w-auto outline-none"
                        onClick={() => setUopen(false)}
                      >
                        Update Save
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default Groups;
