import { createBrowserRouter } from "react-router-dom";
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import Groups from "../views/Groups";
import { Protected } from "../Auth/Protected";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <Protected>
        <Dashboard />
      </Protected>
    ),
    children: [
      {
        path: "groups",
        element: <Groups />,
      },
      {
        path: "signals",
        element: <h1>signals</h1>,
      },
      {
        path: "advisors",
        element: <h1>Advisors</h1>,
      },
      {
        path: "costomers",
        element: <h1>Costomers</h1>,
      },
    ],
  },
]);

export default router;
