import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Lists from "../../components/Lists";
import {
  XMarkIcon,
  VideoCameraIcon,
  ArrowTrendingUpIcon,
  PlusIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { getVideoid } from "../../middleware";
import { addDoc, getDocs, deleteDoc, doc } from "firebase/firestore";
import { auth, db, videoscollectionRef } from "../../firebase/config";
import { NavLink } from "react-router-dom";
import { LinkIcon } from "@heroicons/react/24/outline";
function Home() {

 
  const [mode, setMode] = useState(false);
  const [videos, setVideos] = useState();
  const [deleteID, setVideoID] = useState("");
  const [videourl, setVideourl] = useState("");
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const stats = [
    { name: "Number of users", value: "405" },
    { name: "Number of Groups", value: "30" },
    { name: "Number of signals", value: "3" },
    { name: "Youtube Videos", value:videos?videos.length:"0"  },
  ];
  const inputHandler = (e) => {
    const { value } = e.target;
    setVideourl(value);
  };

  // get Video List
  const getvideourl = async () => {
    const data = await getDocs(videoscollectionRef);
    setVideos(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  // delete video
  const deleteVideo = async () => {
    try {
      const videosdeleteRef = doc(db, "youtube_homepage", deleteID);
      await deleteDoc(videosdeleteRef);
      setOpen(false);
      getvideourl();
    } catch (error) {
      console.log(error);
    }
  };

  // add video List
  const formsubmit = async (e) => {
    e.preventDefault();
    const videoid = getVideoid(videourl);
    try {
      if(videourl!==""){
      await addDoc(videoscollectionRef, { url: videoid });
      getvideourl();
      }
      else{
        setPopup(true)
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const test = () => {
    alert("ok");
  };
  useEffect(() => {
    getvideourl();
  }, []);
  return (
    <div className="main_page">
      {/* Outer container with a dark gray background and rounded corners data show in dashboard */}
      <div className="bg-gray-900 rounded-md">
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div
                key={stat.name}
                className="bg-gray-900 px-4 rounded-md py-6 sm:px-6 lg:px-8"
              >
                <p className="text-sm font-medium leading-6 text-gray-400">
                  {stat.name}
                </p>
                <p className="mt-2 flex items-baseline gap-x-2">
                  <span className="text-4xl font-semibold tracking-tight text-white">
                    {stat.value}
                  </span>
                  {stat.unit ? (
                    <span className="text-sm text-gray-400">{stat.unit}</span>
                  ) : null}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* end commments */}

      {/* add video sections */}

      <div className="mt-10 grid grid-cols-1 gap-x-3 gap-y-8 sm:grid-cols-6">
     

        <div className=" sm:col-span-4">
          All List
          <form onSubmit={formsubmit}>
            <div className="mt-4 grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <div className="mt-2">
                  <div className="mt-2 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                      URL
                    </span>
                    <input
                      type="text"
                      onChange={inputHandler}
                      value={videourl}
                      name="company-website"
                      id="company-website"
                      className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="www.youtube.com/watch?v=75ZnSVEZbGU"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="mt-2">
                  <button
                    type="submit"
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 inline-flex items-center"
                  >
                    <PlusIcon className="h-5 w-5 " />
                    <span>Add Video</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <ul role="list" className="divide-y divide-gray-100">
            {videos &&
              videos.map((items, i) => (
                <li key={i} className="flex  gap-x-2 py-3">
                  <div className="flex  min-w-0 gap-x-4 items-center">
                    <p className="mt-1">{i + 1} . </p>
                 
                    <img
                      src={
                        "https://i.ytimg.com/vi/" + items.url + "/hqdefault.jpg"
                      }
                      className="h-10 w-20"
                    />
                    <div className="min-w-0 flex-auto">
                      <p className="mt-1 flex text-xs leading-5 text-gray-500">
                       <NavLink
                          to={"https://www.youtube.com/watch?v=" + items.url}
                          className="truncate hover:underline hover:text-blue-500"
                          target="blank_page"
                        >
                          {"https://www.youtube.com/watch?v=" + items.url}
                        </NavLink>
                      </p>
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center gap-x-2">
                    <XMarkIcon
                      onClick={() => {
                        setVideoID(items.id);
                        setOpen(true);
                      }}
                      className="h-5 w-5 cursor-pointer text-rose-500"
                      aria-hidden="true"
                    />
                  </div>
                </li>
              ))}
          </ul>
        
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-rose-100">
                      <XMarkIcon
                        className="h-6 w-6 text-rose-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Are you Sure ?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Do you really want to delete these records? This
                          process cannot be undone
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center gap-4">
                    <button
                      type="button"
                      className="inline-flex  justify-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 "
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex  justify-center rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500  "
                      onClick={() => {
                        deleteVideo();
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={popup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setPopup}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-rose-100">
                      <LinkIcon
                        className="h-6 w-6 text-rose-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                      Please enter a valid youtube link
                      </Dialog.Title>
                  
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center gap-4">
                    <button
                      type="button"
                      className="inline-flex  justify-center rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 "
                      onClick={() => setPopup(false)}
                    >
                      Cancel
                    </button>
                   
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Home;
